<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="10"
      height="10"
      viewBox="0 0 172 172"
      style=" fill:#000000;"
  >
    <g
        fill="none"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="1"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill="currentColor">
        <path
            d="M62.39031,10.32c-0.90031,0.01344 -1.74688,0.36281 -2.39188,0.99438l-20.72062,20.55937c-1.34375,1.34375 -1.35719,3.52063 -0.02688,4.86438l48.96625,49.36937l-49.35594,48.96625c-1.35719,1.34375 -1.37062,3.52063 -0.02687,4.86438l20.55937,20.72062c1.34375,1.35719 3.52063,1.37063 4.86438,0.02688l72.53562,-71.94438c1.34375,-1.34375 1.35719,-3.52062 0.01344,-4.87781l-71.93094,-72.52219c-0.65844,-0.67187 -1.55875,-1.03469 -2.48594,-1.02125z"
        ></path>
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  name: "ZArrow"
}
</script>

<style scoped>

</style>
